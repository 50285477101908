html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}
button {
  cursor: pointer;
}

.negative-zIndex {
  z-index: -1;
}
.MuiPaper-root {
  background-color: #2c325c !important;
}
.MuiPickersCalendarHeader-iconButton {
  background-color: #383e66 !important;
}
